/*
FIXME: move (some) themes and animations to seperatae files or a demo site
*/


/* container for positioning..
   - either as trick to vertical align to 100% of the viewport/window height
   - or as corner from whic to grow the popup (abs/fixed pos and then also abs pos -wh-popup relative to this anchor)
   - or to use to set the perspective (so as not to mess with the document body's perspective)
*/
.wh-popup-container
{
  perspective: 800px; /* FIXME: have animation settings on wh-popup-container and then apply this rule specific to some animations? */
  -webkit-perspective: 800px;

  position: fixed;
   /*z-index: 50000; FIXME: think about this? */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 100%; /* FIXME: needed or was this for IE8 ? */
  white-space: nowrap;

  pointer-events: none; /* FIXME: IE11 only :( */
}

/* stretch line-height to 100% viewport so we can vertical-align the wh-popup-chrome */
.wh-popup-container:before
{
  display: inline-block;
  vertical-align: middle;
  height:100%;
  width:0px;
  content: " ";
}
.wh-popup-container.scroll-popup-viewport:before
{
  display: none; /* only messed with CSS reload in 'popup_viewport' scroll mode */
}

/*
Functions as:
- (non-overflowing) positioning container for the closebutton (and possible other chrome)
- NOT IMPLEMENTED: optional positioning hook to make the popup grow starting from the first hook (wh-popup-container)
- !! don't use overflow here, chrome items like the closebutton should be allowed to stick a little outside our border
*/
.wh-popup-container .wh-popup-chrome
{
  position: relative; /* make this the new positioning context, so it'll act like a document viewport ala iframe */
  text-align: left; /* undo the center we inherited from -wh-popup-container */

  /* for centering in our viewport */
  display: inline-block;
  vertical-align: middle;

  pointer-events: none;

  white-space: normal; /* restore */
}

.wh-popup-container.isactive .wh-popup-chrome
{
  pointer-events: auto;
}





  .wh-popup-chrome
{
  vertical-align:middle;
}
  .wh-popup-chrome
, .wh-popup-body-container
{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* legacy */
  .-wh-popup-hide .wh-popup-chrome
, .-wh-popup-hide .-wh-popup-chrome
{
  pointer-events: none;
}

.wh-popup
{
  display:none;
}
.wh-popup-container.isvisible .wh-popup
{
  display:block;
}

/* this will contain the element which was specified to be the content of the popup

This element is here so:
- you aren't forced to make your element an inline-block
- the script doesn't need to add this class to the element you want in the popup (since JS might overwrite the className)
- we can vertical align the content of the popup
- we can apply padding here (no need to specify it on all elements we might want to place in our popup)
 */
.-wh-popup-body
{
}


/*
.wh-popup-chrome.valign .wh-popup-body-container
{
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;

   -webkit-flex-direction: row; / * works with row or column * /
   flex-direction: row;

   -webkit-align-items: center;
   align-items: center;

   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
}
*/

/* IE10+, Firefox, Chrome, Safari, Opera 12.1+ */
.wh-popup-chrome.valign .wh-popup-body-container
{
  height: 100%;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.wh-popup-chrome.valign .wh-popup-body
{
  width: 100%;
}



/**************************************************************************
//
//  Themes
//
*/

/* Default theme */
  .wh-popup-chrome.theme-white
, .-wh-popup-theme-white
{
  background-color: #FFFFFF;
  color: #000000;

  padding: 10px;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
}

  .wh-popup-chrome.theme-black
, .-wh-popup-theme-black
{
  background-color: #000000;
  color: #FFFFFF;

  padding: 10px;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
}

  .wh-popup-closebutton
, .-wh-popup-closebutton
{
  position: absolute;
  top: -10px;
  right: -10px;

  cursor: pointer;
  /* z-index: 50; FIXME prevent need for z-indexes */
}

  .wh-popup-chrome.theme-white .wh-popup-closebutton
, .wh-popup-chrome.theme-black .wh-popup-closebutton
, .wh-popup-theme-white .wh-popup-closebutton
, .wh-popup-theme-black .wh-popup-closebutton
{
  width: 29px;
  height: 29px;
  background: url(button_close.png) no-repeat scroll center center;
}
  .wh-popup-chrome.theme-white .wh-popup-closebutton:hover
 ,.wh-popup-chrome.theme-black .wh-popup-closebutton:hover
, .wh-popup-theme-white .wh-popup-closebutton:hover
, .wh-popup-theme-black .wh-popup-closebutton:hover
{
  background-image: url(button_close_hover.png);
}



/*
Warning for animations!!!

- apply transforms only in combination with .animate, else scales will be applied
  before the popup's onbeforeshow event. This will cause any code measuring
  sizes of popup content to get smaller sizes.
*/



/**************************************************************************
//
//  Animation: Fade
//
*/

.wh-popup-chrome.animin-fade
{
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.wh-popup-container.isvisible .wh-popup-chrome.animin-fade
{
  opacity: 1;
}

.wh-popup-container.ishiding .wh-popup-chrome.animin-fade
{
  opacity: 0;
}



/**************************************************************************
//
//  Animation: Pop
//
*/

.wh-popup-chrome.animin-pop
{
  opacity:    0;

  /* Safari 6 */
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.17, 0.89, 0.33, 1.17)
                    , opacity   0.4s linear;

  /* Safari 6.1+ */
  transition: -webkit-transform 0.4s cubic-bezier(0.17, 0.89, 0.33, 1.17)
            , opacity   0.4s linear;

  transition: transform 0.4s cubic-bezier(0.17, 0.89, 0.33, 1.17)
            , opacity   0.4s linear;

  -webkit-transform:  scale(0);
  transform:          scale(0);
}

.wh-popup-container.isvisible .wh-popup-chrome.animin-pop
{
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.wh-popup-container.ishiding .wh-popup-chrome.animout-pop
{
  -webkit-transform:  scale(0);
  transform:          scale(0);
}



/**************************************************************************
//
//  Animation: Shove
//
*/

  .wh-popup-chrome.animin-shove
, .wh-popup-chrome.-wh-popup-animin-shove
, .-wh-popup-chrome.-wh-popup-animin-shove
{
  opacity: 0;

  -webkit-transform: translate(0,100px); /* Safari & Chrome */
  transform:         translate(0,100px); /* IE10+ & FF16+ */

  /* Safari 6 */
  -webkit-transition: -webkit-transform 0.3s ease-out
                    , opacity           0.4s linear;

  /* Safari 6.1+ */
  transition: -webkit-transform 0.3s ease-out
            , opacity           0.4s linear;

  transition: transform 0.3s ease-out
            , opacity   0.4s linear;
}

  .wh-popup-container.isvisible .wh-popup-chrome.animin-shove
, .wh-popup-container.ishiding .wh-popup-chrome.animin-shove
, .-wh-popup-show .wh-popup-chrome.-wh-popup-animin-shove
, .-wh-popup-show .-wh-popup-chrome.-wh-popup-animin-shove
{
  opacity: 1;
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}

  .wh-popup-container.ishiding .wh-popup-chrome.animout-shove
, .-wh-popup-hide .wh-popup-chrome.-wh-popup-animout-shove
, .-wh-popup-hide .-wh-popup-chrome.-wh-popup-animout-shove
{
  opacity: 0;

  -webkit-transform: translate(0,100px); /* Safari & Chrome */
  transform:         translate(0,100px); /* IE10+ & FF16+ */

  /* Safari 6 */
  -webkit-transition: -webkit-transform 0.3s ease-in
                    , opacity           0.3s linear;

  /* Safari 6.1+ */
  transition: -webkit-transform 0.3s ease-in
            , opacity           0.3s linear;

  transition: transform 0.3s ease-in
            , opacity   0.3s linear;
}



/*
.-wh-popup-chrome.-wh-popup-animin-shove
{
  animation: myfirst 5s;
  -webkit-animation: -animation-shove 5s;
}

@-webkit-keyframes -animation-shove
{
  from   { opacity:    0;
         , transform:  translate(0,100px);
         , transition: transform 0.3s ease-out
                     , opacity   0.4s linear;
         }
  to     { opacity: 1;
         , transform: translate(0,0);
         }
}
*/



/**************************************************************************
//
//  Animation: Flip
//
*/

.wh-popup-chrome.animin-flip
{
  opacity: 0;
  -webkit-transform: rotateX(97deg); /* Safari & Chrome */
  transform:         rotateX(97deg); /* IE10+ & FF16+ */

  -webkit-transition: -webkit-transform 0.2s ease-out
                    , opacity           0.2s linear;

  transition: -webkit-transform 0.2s ease-out
            , opacity           0.2s linear;

  transition: transform 0.2s ease-out
            , opacity   0.2s linear;
}

.wh-popup-container.isvisible .wh-popup-chrome.animin-flip
{
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform:         rotateX(0deg);
}

.wh-popup-container.ishiding .wh-popup-chrome.animin-flip
{
  opacity: 0;
  -webkit-transform: rotateX(97deg); /* Safari & Chrome */
  transform:         rotateX(97deg); /* IE10+ & FF16+ */
}


/**************************************************************************
//
//  Animation: DropIt
//
*/

.wh-popup-chrome.animin-dropit
{
  opacity: 0;

  -webkit-transform: scale(3); /* Safari & Chrome */
  transform:         scale(3); /* IE10+ & FF16+ */

  -webkit-transition: -webkit-transform 0.4s cubic-bezier(.47,.47,.17,1.45)
                    , opacity           0.3s linear;

  transition: -webkit-transform 0.4s cubic-bezier(.47,.47,.17,1.45)
            , opacity           0.3s linear;

  transition: transform 0.4s cubic-bezier(.47,.47,.17,1.45)
            , opacity   0.3s linear;
}

.wh-popup-container.isvisible .wh-popup-chrome.animin-dropit
{
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.wh-popup-container.ishiding .wh-popup-chrome.animin-dropit
{
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}



/**************************************************************************
//
//  Animation: FlipOverdone
//
*/

.wh-popup-chrome.animin-flipoverdone
{
  opacity: 0; /* 360+97 */
  -webkit-transform: scale(0) rotateX(457deg); /* Safari & Chrome */
  transform:         scale(0) rotateX(457deg); /* IE10+ & FF16+ */

  -webkit-transition: -webkit-transform 0.6s ease-out
                    , opacity           0.6s linear;

  transition: -webkit-transform 0.6s ease-out
            , opacity           0.6s linear;

  transition: transform 0.6s ease-out
            , opacity   0.6s linear;
}

.wh-popup-container.isvisible .wh-popup-chrome.animin-flipoverdone
{
  opacity: 1;
  -webkit-transform: scale(1) rotateX(0deg);
  transform:         scale(1) rotateX(0deg);
}

.wh-popup-container.ishiding .wh-popup-chrome.animin-flipoverdone
{
  opacity: 0; /* 360+97 */
  -webkit-transform: scale(0) rotateX(457deg); /* Safari & Chrome */
  transform:         scale(0) rotateX(457deg); /* IE10+ & FF16+ */

  -webkit-transition: -webkit-transform 0.6s ease-in
                    , opacity           0.6s linear;

  transition: -webkit-transform 0.6s ease-in
            , opacity           0.6s linear;

  transition: transform 0.6s ease-in
            , opacity   0.6s linear;
}



/**************************************************************************
//
//  Modality layer
//
*/

  .-wh-modalitylayer
, .wh-modalitylayer
{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.4);
  /* z-index: 50000; FIXME prevent need for z-indexes */

  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  pointer-events: none;
}

/* only in overlay mode should this be used */
  .-wh-modalitylayer.-wh-modalitylayer-show
, .wh-modalitylayer.isvisible
{
  opacity: 1;
  pointer-events: inherit;
}



/*html.-wh-modal-popup-active*/
.-wh-modality-anim-blur
{
  -webkit-transition: -webkit-filter .50s;
/*  background-color:   rgba(0,128,255,0);*/
}
.-wh-modal-popup-active .-wh-modality-anim-blur/*.-wh-modality-show*/
{
  -webkit-filter: blur(2px) grayscale(50%);
/*  background-color:   rgba(0,128,255,0.25);*/
}
/*
.-wh-modal-popup-active .-wh-modality-anim-blur.-wh-modality-hide
{
  -webkit-filter: blur(0px) grayscale(0%);
}
*/


.-wh-modality-anim-mark
{
  -webkit-transition: -webkit-filter .50s
                    , -webkit-transform .50s;
}
.-wh-modal-popup-active .-wh-modality-anim-mark
{
  -webkit-filter: blur(2px) grayscale(50%);
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
}

