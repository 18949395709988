@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0;
  line-height: 130%;
  -webkit-text-size-adjust: none;
}

body {
  overflow-y: scroll;
  background-color: #ffffff;
  color: #333333;
  font-family: Tahoma, Geneva, sans-serif, Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
    text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
}

#frethangmat {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

/** Popup */
.wh-popup-chrome {
  padding: 10px 20px;
  background-color: #99AB32;
  color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
}

.wh-popup-chrome.valign .wh-popup-body-container {
  display: block; /* the flex stuff just doesn't work */
}

.-wh-popup-modalitylayer {
  background-color: #000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

/* NOTE: don't specify a display property for the dialog! */
.-wh-popup {
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  overflow: auto;
}

.-wh-popup h3 {
  color: #6a7623;
}

.-wh-popup th {
  color: #fff7c7 !important;
}

.-wh-popup td {
  border-bottom: 1px dashed #93a239;
}

.-wh-popup-closebutton {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 29px;
  height: 29px;
  background: url(../web/img/button_close.png) no-repeat scroll center center;
  cursor: pointer;
  z-index: 50;
}

.-wh-popup-closebutton:hover {
  background: url(../web/img/button_close_hover.png) no-repeat scroll center center;
}

/** Images */
img {
  border: none;
}

img.centeredimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.leftimage {
  float: left;
  padding-right: 10px;
}

img.rightimage {
  float: right;
  padding-left: 10px;
}

a[href] {
  text-decoration: none;
  color: inherit;
}

/** Links */
.bodycontainer a[href] {
  color: #778527;
  transition: color 0.2s;
}

.bodycontainer a[href]:hover {
  color: #e35725;
}

a[href]:focus {
  outline: 0;
}

a[href]:active {
  outline: 0;
}

#popupcontents a[href] {
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.2s;
}

#popupcontents a[href]:hover {
  color: #fff7c7;
}

/** Text layout */
hr {
  height: 1px;
  color: #fffffff;
  background-color: #fffffff;
  margin: 20px 0;
  border: #778527 1px dashed;
  border-style: none none dashed;
}

img.applypopup {
  position: relative;
  top: 4px;
  cursor: pointer;
}

.verdieping {
  display: inline;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url("../web/img/i.png") no-repeat scroll top left transparent;
  position: relative;
  top: 3px;
  font-size: 0;
}

div.popup {
  display: none;
}

.verdieping .contents {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px solid #9ba643;
  background-color: #778527;
  min-width: 150px;
  padding: 10px;
  font-size: 100%;
  white-space: nowrap;
  color: #fff;
}

h1 {
  color: #778527;
  font-size: 26px;
  margin: 0;
  line-height: 80%;
}

h2 {
  color: #778527;
  font-size: 18px;
  line-height: 40%;
  margin: 16px 0 0 0;
}

h3 {
  color: #778527;
  font-size: 110%;
  margin: 16px 0 0 0;
}

h4 {
  color: #778527;
  margin: 0;
}

p.center {
  text-align: center;
}

/** FAQ questions */
.faq .question {
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
  margin: 15px 0 0 0;
  padding: 0 0 0 22px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: color 500ms ease-out, background-color 500ms ease-out;
  transition: color 500ms ease-out, background-color 500ms ease-out;
  /*background: url('../web/img/faqclosed.png') no-repeat 10px 11px transparent;*/
  height: 30px;
  line-height: 30px;
}

.faq .question.open,
.faq .question:hover {
  background-color: #ffffff;
  color: #333333;
}

.faq .question.open {
  /*background: url('../web/img/faqopen.png') no-repeat 7px 14px #ffffff;*/
}

.faq .answer {
  overflow: hidden;
  margin: 0 15px -10px 0;
}

/** Other pagecontent layout */
#pagecontent {
  margin: 0 auto;
}

#pagecontent,
#pagecontent #pagetop,
#pagecontent #bannerscontainer {
  width: 960px;
}

#pagecontent #pagetop {
  height: 310px;
}

#pagecontent #pagetop .logocontainer {
  float: left;
  width: 270px;
}

#pagecontent #pagetop .menu-slideshow {
  float: left;
  width: 690px;
}

#pagecontent #containers {
  margin: 20px 0;
  height: 35px;
  width: 100%;
}

#pagecontent #searchcontainer {
  height: 35px;
  background-color: #99ab32;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 2px 2px #80884f;
  box-shadow: 2px 2px 2px 2px #80884f;
  float: left;
  padding: 0 14px 0 0;
}

#pagecontent #searchcontainer .searchfield {
  padding: 7px 0 0 15px;
}

#pagecontent #searchcontainer .searchfield > input {
  background: url("../web/img/magnifier.png") no-repeat scroll 1px -1px transparent;
  border: 0 none;
  border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  color: #FFFFFF;
  font-size: 10px;
  height: 23px;
  line-height: 23px;
  padding: 2px 68px 0 40px;
  margin: -2px 0 0 -4px;
  transition: width 300ms ease-out 0s, border-color 500ms ease 0s, background-color 0.15s ease 0s;
  -webkit-transition: width 300ms ease-out 0s, border-color 500ms ease 0s, background-color 0.15s ease 0s;
  width: 50px;
  outline: none;
}

#pagecontent #searchcontainer .searchfield > input:focus {
  width: 600px;
  /*border-color: #000000;
  background-color: #eaeaea;*/
}

#pagecontent #searchcontainer .searchfield > input::-webkit-input-placeholder {
  color: #ccc;
  font-style: normal;
}

#pagecontent #searchcontainer .searchfield > input::-moz-placeholder {
  color: #ccc;
  font-style: normal;
}

#pagecontent #searchcontainer .searchfield > input::placeholder {
  color: #ccc;
  font-style: normal;
}

/** Tables */
.informationtable, .nametable {
  margin: 16px 0;
  width: 670px;
  border-collapse: collapse;
  -webkit-border-radius: 10px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 1px 1px 1px #80884F;
}

.informationtable th, .nametable th, .comparisontable th {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 5px;
  color: #e35725;
}

.informationtable tr.alt td, .nametable tr.alt td, .comparisontable tr.alt td {
  background-color: #fff7de;
}

.informationtable td, .informationtable th {
  padding: 3px 7px 2px 7px;
}

.nametable td, .nametable th {
  padding: 3px 7px 2px 7px;
  vertical-align: top;
  width: 25%;
}

.comparisontable {
  margin: 16px 0;
  min-width: 300px;
  border-collapse: collapse;
  -webkit-border-radius: 10px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 1px 1px 1px #80884F;
}

.comparisontable td, .comparisontable th {
  padding: 3px 7px 2px 7px;
  vertical-align: top;
}

.comparisontable td.label, .comparisontable th.label {
  text-align: left;
  width: 150px;
}

.comparisontable a {
  position: relative;
  color: #000000;
  text-decoration: none;
}

.comparisontable a.orange {
  color: #FF6600;
}

.comparisontable a span {
  display: none;
}

.comparisontable a:hover span {
  display: block;
  z-index: 30;
  position: absolute;
  top: 1, 8em;
  left: 6em;
}

td.ferretprofile {
  width: 120px;
}

/* Not used (was forum) */
#pagecontent #extrarightcontainer {
  height: 35px;
  width: 184px;
  float: right;
  background-color: #99AB32;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 2px 2px #80884F;
  box-shadow: 2px 2px 2px 2px #80884F;
}

#pagecontent #extrarightcontainer p {
  color: #ffffff;
  font-size: 11px;
  text-align: center;
  margin: 10px 0;
}

#pagecontent #mainmenu {
  padding: 25px 0 0 0;
  margin: 10px 0 0 0;
}

#pagecontent #mainmenu a.menulink,
#pagecontent #mainmenu a.menulink.selected {
  text-decoration: none;
  color: inherit;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 15px;
  padding: 0 10px;
}

ul.wh-menu:not(.wh-scrollableview):not(.wh-menubar) {
  overflow-x: visible;
  overflow-y: visible;
}

ul.wh-menu-detached {
  background-color: #a5b862;
  text-decoration: none;
  color: #ffffff !important;
  border-radius: 20px;
  box-shadow: 1px 2px 2px 0 #666666;
  padding: 20px;
  list-style-type: none;
  overflow: hidden;
}

ul.wh-menu-detached li {
  position: relative;
}

ul.wh-menu-detached li,
ul.wh-menu-detached a {
  color: #ffffff !important;
  padding: 4px;
  font-size: 13px;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

ul.wh-menu-detached li a:hover {
  color: #000000 !important;
  text-decoration: none;
}

ul.wh-menu-detached li ul {
  position: absolute;
  left: 100%;
  margin-left: 25px;
  top: -15px;
}

#pagecontent .bodycontainer .submenu {
  width: 285px;
}

#pagecontent .bodycontainer .submenu a.submenuitem {
  color: #000000;
  display: block;
  margin: 5px 0 10px 0;
  text-decoration: none;
  padding: 0 0 0 15px;
  width: 253px; /* 268 */
  height: 35px;
  background: url("../web/img/menulist.png") scroll top left no-repeat transparent;
  line-height: 30px;
}

#pagecontent .bodycontainer .submenu a.submenuitem.selected,
#pagecontent .bodycontainer .submenu a:hover.submenuitem {
  color: #778527;
  display: block;
  margin: 5px 0 10px 0;
  text-decoration: none;
  padding: 0 0 0 15px;
  width: 253px; /* 268 */
  height: 35px;
  background: url("../web/img/menulist-selected.png") scroll top left no-repeat transparent;
  line-height: 30px;
}

#pagecontent .bodycontainer .submenu a.submenuitem.selected:last-child {
  margin: 5px 0 50px 0;
}

#pagecontent .bodycontainer .pagebody {
  width: 700px;
  padding: 0 0 50px 0;
}

#pagecontent .bodycontainer .pagebody.nosubmenu {
  width: 960px;
}

#pagecontent #slideshow {
  margin: 45px 0 0 0;
  width: 685px;
  height: 188px;
  -webkit-border-radius: 10px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 1px 1px 1px #80884F;
}

#pagecontent #bannerscontainer {
  margin: 20px 0 0 0;
  height: 175px;
}

#pagecontent #bannerscontainer .banner {
  float: left;
  width: 250px;
  height: 125px;
  padding: 25px;
  margin: 0 30px 0 0;
  background-color: #fff7c7;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#pagecontent #bannerscontainer .banner.last {
  margin-right: 0;
}

#pagecontent #bannerscontainer .header {
  font-size: 16px;
  padding: 0 0 0 5px;
}

#pagecontent #bannerscontainer .text {
  padding: 7px 0 0 0;
  font-size: 10px;
}

#pagecontent #footercontainer {
  background-color: #99ab32;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  width: 920px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 2px 2px 2px 2px #80884F;
  margin: 0 0 30px 0;
}

#pagecontent #footercontainer .footeritem {
  width: 230px;
  color: #ffffff;
}

#pagecontent #footercontainer .footeritem .header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 6px 0 25px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#pagecontent #footercontainer .footeritem li {
  display: block;
  margin: 5px 0 5px -40px;
  background-image: url("../web/img/menulist_footer.png");
  background-position: left top;
  background-repeat: no-repeat;
}

#pagecontent #footercontainer .footeritem a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  padding-left: 25px;
}

#pagecontent #copyrightcontainer {
  background-color: #FFF7C7;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 5px 20px;
  width: 920px;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 2px 2px #e7e3ce;
  box-shadow: 2px 2px 2px 2px #e7e3ce;
  margin: 0 0 30px 0;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 11px;
}

#pagecontent #copyrightcontainer .copyrightitem {
  color: #333333;
}

#pagecontent #copyrightcontainer .copyrightitem a {
  display: block;
  color: #333333;
  text-decoration: none;
  padding-left: 25px;
}

/* hoofdmenu
*/
ul.wh-menubar {
  margin-top: 20px;
}

ul.wh-menubar > li {
  line-height: 40px;
  color: #000000;
  padding: 0px 20px;
}

ul.wh-menubar > li.hassubmenu:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 8px;
  width: 7px;
  height: 7px;
  display: block;
  background: url("../web/img/menudown.png") no-repeat;
}

ul.wh-menubar > li > a {
  font: 15px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

ul.wh-menubar > li.selected,
ul.wh-menubar > li.current {
  background-color: #99AB32;
  border-radius: 20px;
  box-shadow: 1px 2px 2px 0 #666666;
  color: #FFFFFF;
}

ul.wh-menulist {
  background: #a5b862;
  border-radius: 20px;
  box-shadow: 1px 2px 2px 0 #666666;
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

ul.wh-menulist.level-1 {
  margin-top: 10px;
}

ul.wh-menulist.level-2 {
  margin-left: 17px;
}

ul.wh-menulist li {
  padding: 5px 20px;
  font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  color: #ffffff;
}

ul.wh-menulist li.selected {
  color: #000000;
}

ul.wh-menulist li.hassubmenu:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 0px;
  width: 7px;
  height: 7px;
  display: block;
  background: url("../web/img/menuright.png") no-repeat;
}

/* Mette's originele menucode
ul.wh-menu.wh-menu-menubar > li
{
  display: inline-block;
  margin-top: 25px;
}
ul.wh-menu.wh-menu-menubar > li a
{
  display: inline-block;
}
ul.wh-menu.wh-menu-menubar li
{
  padding: 0 10px;
}
ul.wh-menu.wh-menu-menubar li.hassubitems
{
  background: url('../web/img/arrowdown.png') scroll center 40px no-repeat #ffffff;
}
.wh-menu-menubar
{
  margin: 0;
  padding: 0;
  background:#ffffff;
}
.wh-menu-disabled
{
  color:#7f7f7f !important;
}
.wh-menu li.wh-menu-selected
{
}
ul.wh-menu.wh-menu-menubar li.hassubitems.wh-menu-selected
{
}
  .wh-menu-menubar > li.wh-menu-selected
, .wh-menu-menubar > li.selected
{
  background-color: #99AB32 !important;
  text-decoration: none;
}
.popupmenu, .wh-menu-menubar ul
{
}
.wh-menu-divider
{
  border-top:1px solid #ffffff;
  padding:2px 0;
}*/
#scrollup {
  position: fixed;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  cursor: pointer;
  background: red;
  width: 46px;
  height: 46px;
  background: url("../web/img/scroll.png") no-repeat;
  bottom: 20px;
  margin-left: 750px;
  margin-bottom: 60px;
  left: 50%;
}

html.scrolled #scrollup {
  opacity: 1;
}

/** Webpack */
/* Global tag, containing the complete webpack item */
/* Webpack item title */
.whwp .whwp-title {
  font-weight: bold;
}

/* All links */
.whwp a {
  color: #0000AA;
}

/* Characteristics shared between input and button style links */
.whwp .whwp-button, .whwp .whwp-button:hover {
  width: 100px;
  background-color: #778527;
  text-align: center;
  color: #ffffff;
  border: outset 2px;
  border-right-color: #808080;
  border-bottom-color: #808080;
  margin: 3px 3px 3px 0px;
}

/* Links with an input button appearance */
.whwp a.whwp-button, .whwp a.whwp-button:hover {
  display: block;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 2px 5px;
}

.whwp input.whwp-button {
  /* IE/FF work around: first pointer, then hand */
  cursor: pointer;
  cursor: hand;
  font-family: arial, helvetica, sans-serif; /* Inherit font-family */
  font-size: 100%; /* Inherit font-size */
  background-color: #99AB32;
}

.whwp .whwp-questiongroup {
  margin: 20px 0;
  background-color: #FFFFFF;
}

/* Individual questions */
.whwp .whwp-questiongroup .whwp-question {
  margin: 10px 0px;
}

/* Individual question labels */
.whwp .whwp-questiongroup .whwp-question label,
.whwp .whwp-questiongroup .whwp-question {
  font-style: italic;
  margin: 10px 0px 4px 0px;
}

/* note: .whwp-formgroup is actually a grouping for options in a checkbox or radiobox question */
.whwp .whwp-questiongroup .whwp-question input, .whwp .whwp-questiongroup .whwp-question textarea,
.whwp .whwp-questiongroup .whwp-question option, .whwp label input, .whwp label textarea,
.whwp .whwp-questiongroup .whwp-question .whwp-formgroup label {
  font-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
}

/* 2011-09-23 */
.whwp-formgroup .whwp-option.error input {
  border-color: #FF0000;
}

/***
Guestbook and Forum styles
***/
.whwp .whwp-entry {
  border: 1px solid #000000;
  margin: 10px 0px;
}

.whwp .whwp-entry .whwp-header {
  background-color: #D0D0D0;
  font-weight: bold;
  padding: 4px;
}

.whwp .whwp-entry .whwp-message {
  padding: 4px;
}

.whwp .whwp-entry .whwp-message blockquote {
  margin-left: 1pt;
  border-left: 1pt solid #000000;
  padding-left: 5pt;
}

.whwp .whwp-entry .whwp-footer {
  padding: 5px;
}

.whwp table.whwp-table {
  border: solid 1px #000000;
  border-collapse: collapse;
  width: 100%;
  font-size: 100%; /* Ensure IE picks up the inherited font-size for this table */
}

.whwp table.whwp-table th {
  text-align: left;
  padding: 2px 5px;
  background-color: #D0D0D0;
  vertical-align: top;
  white-space: nowrap;
  color: #000000;
}

.whwp table.whwp-table td {
  text-align: left;
  padding: 2px 5px;
  vertical-align: top;
}

.whwp .whwp-odd {
  background-color: #EEEEEE;
}

.whwp .whwp-even {
  background-color: #FFFFFF;
}

.whwp .whwp-errors {
  color: #FF0000;
}

.whwp table.whwp-attachments {
  font-size: 100%;
}

/* End of the standard webpack CSS definitions */
ul.wh-menulist.open {
  overflow: visible;
}

/* QUIZ styling */
.quizpage:not(.active),
.quizpage:not(.answered) .quizfeedback, .emailfeedback {
  display: none;
}

.question,
.scoreinfo {
  padding: 20px 0 10px 0;
}

.quizpage label {
  display: block;
  padding: 4px 0 0 0;
}

.quizpage label.score {
  display: inline-block;
  min-width: 150px;
}

.answerit {
  margin-top: 20px;
}